import * as React from 'react';
import Button from '@mui/material/Button';

import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

export default function ConfirmDialogModal({ sx, ...props }) {
  //   const [open, setOpen] = React.useState(false);

  const handleYes = () => {
    // props.handleDelete();
    // props.setOpen(false);
    window.location.replace('/login');
    localStorage.removeItem('token');
  };
  return (
    <React.Fragment>
      {/* <Tooltip title={`Delete ${props.tooltipTitle}`}> */}

      <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        {/* <DialogTitle
          id="alert-dialog-title"
          dividers
          sx={{
            background: (theme) => theme.palette.primary.main,
            pb: 0,
            pt: 1,
          }}
        >
          <Box>

          </Box>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',

              mb: 1,
              color: '#fff',
            }}
          >
            <WarningRoundedIcon sx={{ mr: 1 }} />
            Delete {props.name}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 10,
            top: 5,
            color: (theme) => theme.palette.warning.main,
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogTitle
          id="lease-addform-title"
          style={{
            backgroundColor: '#1976d2',
            marginBottom: '5px',
            padding: '5px',
            paddingBottom: '3px',
            paddingTop: '2px',
            color: 'white',
            textAlign: 'start',
            fontSize: '17px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '360px',
          }}
        >
          <Box sx={{ margin: 'auto' }}>
            {/* <WarningRoundedIcon
              sx={{ mr: "3px", pb: "6px", fontSize: "28px" }}
              style={{ color: "#eb4934" }}
            /> */}
            Logout Confirmation
          </Box>
          <CloseIcon
            // onClick={handleClose()}
            onClick={() => props.setOpen(false)}
            sx={{
              color: 'grey.400',
              '&:hover': {
                color: '#f94449',
              },
              fontSize: '21px',
            }}
            id="lease-addform-close-icon"
          />
        </DialogTitle>
        <DialogContent
          sx={{
            pb: 1,
            mt: 1,
          }}
        >
          <DialogContentText id="alert-dialog-description " sx={{ pb: 2 }}>
            Are u sure want logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: '10px' }}>
          <Button
            variant="outlined"
            onClick={() => props.setOpen(false)}
            id="delete-confirm-no-btn"
            sx={{ textTransform: 'none', border: 'none', p: '2px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleYes}
            autoFocus
            id="delete-confirm-yes-btn"
            sx={{ textTransform: 'none', p: '1px', fontSize: '13px' }}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
