import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCvWM3AkeQgpfmcIoiZks4NlCbL2yJlhkc",
  authDomain: "aidform.firebaseapp.com",
  projectId: "aidform",
  storageBucket: "aidform.appspot.com",
  messagingSenderId: "227744793297",
  appId: "1:227744793297:web:8cc7df29f73a7538d13bf0",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.database().ref();
export default db;
