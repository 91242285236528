import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Axios from "axios";

export default function ProtectedRoutes() {
  const API_URL = process.env.React_App_Api;
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    isAuthenticated: null, // Initial state: unknown
    userData: null, // User data object
    error: null, // Any potential errors
  });

  async function verifyVendor() {
    try {
      const token = localStorage.getItem("token");
      // Simulate API call delay (can be removed in production)
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      const res = await Axios.get(`${API_URL}/register/getuser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("RESPONSES", res.data);
      if (res.data) {
        setAuthState({
          isAuthenticated: true,
          userData: {
            role: res.data.roleId.map((role) => role.roleName),
            district: res.data.district,
            email: res.data.email,
            userId: res.data._id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
          },
          error: null,
        });
      } else {
        setAuthState({ isAuthenticated: false, userData: null, error: null });
      }
    } catch (err) {
      setAuthState({ isAuthenticated: false, userData: null, error: err });
    }
  }

  console.log(authState?.userData);

  useEffect(() => {
    verifyVendor();
  }, []);

  if (authState.isAuthenticated === null) {
    return <div>loading....</div>; // Display loader while fetching data
  } else if (authState.isAuthenticated) {
    return (
      <Outlet context={authState} /> // Pass user data as context
    );
  } else {
    // Handle authentication failure (redirect or display error message)
    if (authState.error) {
      console.error("Authentication error:", authState.error);
      // Optionally display an error message to the user
    }
    return navigate("/login"); // Redirect to login
  }
}

// import * as React from 'react';
// import { Outlet, Navigate } from 'react-router-dom';
// import axios from 'axios';

// const ProtectedRoutes = () => {
//   const [isLoading, setIsLoading] = React.useState(true);
//   const [isAuthenticated, setIsAuthenticated] = React.useState(false);
//   const [userData, setUserData] = React.useState({
//     role: '',
//   });
//   console.log('ROLENAME', userData.role);

//   React.useEffect(() => {
//     const checkToken = async () => {
//       const token = localStorage.getItem('token');
//       console.log('TOken', token);
//       if (!token) {
//         setIsAuthenticated(false);
//         setIsLoading(false);
//         return;
//       }
//       try {
//         const response = await axios.get(
//           'http://localhost:9000/register/getuser',
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         if (response.data) {
//           setIsAuthenticated(response.data);
//           setUserData({
//             role: response.data.roleId.roleName,
//           });
//         } else {
//           setIsAuthenticated(false);
//         }
//       } catch (error) {
//         console.error('Error checking token:', error);
//         setIsAuthenticated(false);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     checkToken();
//   }, []);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (isAuthenticated) {
//     return <Outlet context={userData} />;
//   }

//   return <Navigate to="/login" />;
// };

// export default ProtectedRoutes;
