import { Grid, Typography } from "@mui/material";
import React from "react";

const GridTemplete = (props) => {
  console.log("All Name", props.title, props.content);
  return (
    <Grid xs={6} sm={6} md={props.md} sx={{ mt: 2, p: "5px" }}>
      <Typography
        sx={{ color: "#1976d2", fontWeight: "bold", fontSize: "15px" }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          wordBreak: "break-word",
        }}
      >
        {props.content}
      </Typography>
    </Grid>
  );
};

export default GridTemplete;
