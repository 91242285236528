import './App.css';
import React from 'react';
import MiniDrawer from '../component/Drawer';
import { Route, Routes } from 'react-router-dom';
import Login from '../auth/Login';
import ProtectedRoutes from '../utils/protectedRoutes';
// import ServerErrorPage from '../ServerErrorPage';
// import NotFound from '../helper/errorPages/NotFoundPage';
// import Alerts from '../helper/Alert';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

const API_URL = process.env.React_App_Api;

const checkTokenExpiration = (navigate) => {
  const expirationTime = parseInt(localStorage.getItem('tokenExpiration'), 10);
  const currentTime = new Date().getTime();

  if (currentTime > expirationTime) {
    alert('Session Expired');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    window.location.href = './login';
    // navigate('/login');
  }
};

const refreshToken = async () => {
  try {
    const response = await Axios.post(`${API_URL}/register/generateToken`, {
      refreshToken: localStorage.getItem('refreshToken'),
    });
    const { accessToken, refreshToken } = response.data;
    const expirationTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('tokenExpiration', expirationTime);
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
};

function App() {
  const navigate = useNavigate();

  React.useEffect(() => {
    let activityTimeout;

    const handleUserActivity = () => {
      clearTimeout(activityTimeout);
      const expirationTime = parseInt(
        localStorage.getItem('tokenExpiration'),
        10
      );
      const currentTime = new Date().getTime();
      const timeLeft = expirationTime - currentTime;

      if (timeLeft < 60 * 1000) {
        // If less than 1 minute left
        refreshToken();
      }
    };

    const interval = setInterval(() => {
      checkTokenExpiration(navigate);
    }, 60 * 1000); // Check every 10 seconds

    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    return () => {
      clearInterval(interval);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('scroll', handleUserActivity);
      clearTimeout(activityTimeout);
    };
  }, [navigate]);

  return (
    <div className="App" style={{ overflow: 'hidden' }}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/*" element={<MiniDrawer />} />
        </Route>
        {/* <Route path="*" element={<ServerErrorPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
