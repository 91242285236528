import * as React from 'react';
// import Avatar from "@mui/material/Avatar";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  InputAdornment,
  InputLabel,
  FormControl,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alerts from '../helper/Alert';
import ServerErrorPage from '../ServerErrorPage';

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const theme = createTheme();

export default function SignIn() {
  //
  const API_URL = process.env.React_App_Api;
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  // const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      await axios
        .post(`${API_URL}/register/login`, {
          email: data.get('email'),
          password: data.get('password'),
        })
        .then((response) => {
          console.log('RESPONSE', response);
          const { accessToken, refreshToken } = response.data;
          const expirationTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
          localStorage.setItem('token', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('tokenExpiration', expirationTime);
          navigate('/processAid');
        });
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        setErrorMessage(
          'An error occurred while fetching data. Please try again later.'
        );
        // setError(error.response.data);
        // setOpen(true);
      } else if (error.response && error.response.status === 400) {
        return setAlert({
          open: true,
          message: error.response.data?.error,
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        // Handle other errors
        console.error('Error:', error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const handleToggleClose = () => {
  //   setLoading(false);
  // };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
        anchorOrigin={alert.anchorOrigin}
      />
      {errorMessage ? (
        <ServerErrorPage />
      ) : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* <Avatar> */}
              {/* <LockOutlinedIcon /> */}
              <img alt="logo" src="/idea-logo.png" width="32px" height="32px" />
              {/* </Avatar> */}
              <Typography component="h1" variant="h5" sx={{ mt: '3px' }}>
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="login-email-id"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="login-pass-id"
                  autoComplete="current-password"
                /> */}
                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                <FormControl variant="outlined" required fullWidth>
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    size="small"
                    name="password"
                    label="Password"
                    autoComplete="off"
                  />
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  id="btn_login_submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <NavLink to="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </NavLink>
              </Grid>
            </Grid> */}
              </Box>
            </Box>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Container>
        </ThemeProvider>
      )}
    </>
  );
}
