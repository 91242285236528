import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";

export default function TextFields({
  placeholder,
  label,
  control,
  name,
  error,
  type,
  id,
  required,
  disabled,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth className={error ? "Text-error-field" : ""}>
          <TextField
            required={required}
            size="small"
            id={id}
            label={label}
            variant="outlined"
            type={type}
            placeholder={placeholder}
            disabled={disabled || ""}
            {...field}
            error={Boolean(error)}
            helperText={error ? error.message : ""}
          />
        </FormControl>
      )}
    />
  );
}
