import React, { Fragment, useState, useEffect, useRef } from 'react';

import { Chip } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import Axios from 'axios';
// import "../../../style/Setting.css";

const Volunteers = () => {
  const AuthHeaderRef = useRef({
    headers: {
      'Content-Type': 'application/json',

      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const [listall, setListAll] = useState([]);

  //list all the user
  const [abilityList, setAbilityList] = useState([]);
  console.log('list', abilityList);

  const API_URL = process.env.React_App_Api;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localAuthHeader = AuthHeaderRef.current;

        const registerResponse = await Axios.get(
          `${API_URL}/register/getall`,
          localAuthHeader
        );
        setListAll(registerResponse.data);
        const permissionResponse = await Axios.get(
          `${API_URL}/permission/listall`,
          localAuthHeader
        );
        setAbilityList(permissionResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <TableContainer component={Paper} id="table-container">
        <Table id="table" size="small" aria-label="a dense table">
          <TableHead id="table-header">
            <TableRow>
              <TableCell id="table-header-align">First Name</TableCell>
              <TableCell id="table-header-align">Last Name</TableCell>
              <TableCell id="table-header-align">District</TableCell>
              <TableCell id="table-header-align">Roles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listall?.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>
                  {user.district.map((name) => (
                    <Chip key={name} label={name} />
                  ))}
                </TableCell>
                <TableCell>
                  {abilityList?.map((name) =>
                    user.roleId.includes(name._id) ? (
                      <Chip key={name._id} label={name.roleName} />
                    ) : null
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default Volunteers;
