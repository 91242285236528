import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

const Viewstep6 = (props) => {
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Institution Name 
          "
          content={props.currentUserView.uname}
          md="4"
        />
        <GridTemplete
          title=" Email 
          "
          content={props.currentUserView.unemail}
          md="4"
        />
        <GridTemplete
          title=" MobileNumber 
          "
          content={props.currentUserView.unmobileNumber}
          md="4"
        />
        <GridTemplete
          title=" Address 
          "
          content={props.currentUserView.unaddress}
          md="4"
        />
        <GridTemplete
          title="City 
          "
          content={props.currentUserView.uncity}
          md="4"
        />
        <GridTemplete
          title="State"
          content={props.currentUserView.unstate}
          md="4"
        />

        <GridTemplete
          title="Pin Code"
          content={props.currentUserView.unpincode}
          md="4"
        />
        <GridTemplete
          title="Beneficiary Name 
          "
          content={props.currentUserView.beneficiaryname}
          md="4"
        />
        <GridTemplete
          title="Beneficiary Mobile Number
          "
          content={props.currentUserView.beneficiaryphone}
          md="4"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep6;
