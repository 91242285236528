import * as React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material';

const StyleChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-root': {
    color: theme.palette.success,
  },
}));

export default function BasicChip({ ...props }) {
  return (
    <StyleChip
      color={props.color}
      variant={props.variant}
      icon={props.startDecorator}
      label={props.label}
      //   sx={props.sx}
      onDelete={props.onDelete}
      size={props.size}
    />
  );
}
