import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Axios from 'axios';
import Alerts from '../helper/Alert';
import '../style/DialogHeader.css';

const schema = yup.object().shape({
  password: yup.string().required('Password is required'),
  newpassword: yup
    .string()
    .required('New Password is required')
    .test(
      'passwords-match',
      'The old password and newpassword cannot be same',
      function (value) {
        return this.parent.password !== value;
      }
    ),
  confirmnewpassword: yup
    .string()
    .required('Confirm New Password is required')
    .oneOf(
      [yup.ref('newpassword'), null],
      'Confirm password must match the New password'
    ),
});

export default function PasswordDialog({
  password,
  setPassword,
  setIsPassword,
  userId,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      newpassword: '',
      confirmnewpassword: '',
    },
  });

  const API_URL = process.env.React_App_Api;

  const { userData } = useOutletContext();

  const [showPassword, setShowPassword] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleClose = () => {
    setPassword(false);
    setIsPassword(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    const payload = {
      password: data.password,
      newpassword: data.newpassword,
    };
    try {
      await Axios.patch(
        `${API_URL}/aid/passupdate/${userData.userId}`,
        payload
      ).then((res) => {
        console.log('RES PASS', res.status);
        setAlert({
          open: true,
          message: 'Password updated successfully',
          severity: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      });
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setAlert({
          open: true,
          message: error.response.data?.error,
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        // setTimeout(() => {
        //   handleClose();
        // }, 2000);
      }
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <div>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
        anchorOrigin={alert.anchorOrigin}
      />
      <Dialog
        open={password}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="dialog-header">
          <p className="dialog-header-text">Reset Password</p>
          <CloseIcon onClick={handleClose} className="dialog-closeicon" />
        </DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <FormControl
                variant="outlined"
                required
                fullWidth
                sx={{ marginTop: '8px' }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Old Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Old Password"
                  autoComplete="off"
                />
                {errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />

          <hr />

          <Controller
            control={control}
            name="newpassword"
            render={({ field }) => (
              <FormControl
                variant="outlined"
                required
                fullWidth
                sx={{ marginTop: '8px' }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                  autoComplete="off"
                />
                {errors.newpassword && (
                  <FormHelperText error>
                    {errors.newpassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="confirmnewpassword"
            render={({ field }) => (
              <FormControl
                variant="outlined"
                required
                fullWidth
                sx={{ marginTop: '8px' }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm New Password"
                  autoComplete="off"
                />
                {errors.confirmnewpassword && (
                  <FormHelperText error>
                    {errors.confirmnewpassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
