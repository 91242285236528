import { Box, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

const Viewstep5 = (props) => {
  function AmountFormat(amount) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2, // Adjust as needed
    }).format(amount);
  }
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Aid Amount Requested
 
 
          "
          content={AmountFormat(props.currentUserView.amtrequested)}
          md="6"
        />
        <GridTemplete
          title="Request Aid Type 
 
        
 
"
          content={props.currentUserView.aidtype}
          md="6"
        />
        <GridTemplete
          title="Reason for Requesting Aid

 

          "
          content={props.currentUserView.reasonforrequesting}
          md="6"
        />
        <GridTemplete
          title="multiple years Request

 

          "
          content={props.currentUserView.multipleyears}
          md="6"
        />
        {props.currentUserView.multipleyears === "yes" ? (
          <GridTemplete
            title=" supporting years

 

          "
            content={props.currentUserView.yearsofsupportisneeded}
            md="6"
          />
        ) : (
          ""
        )}
      </Grid>
    </Box>
  );
};

export default Viewstep5;
