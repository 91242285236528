import * as React from 'react';
import Button from '@mui/material/Button';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';

export default function ConfirmDialogModal(props) {
  const API_URL = process.env.React_App_Api;
  const handleYes = () => {
    try {
      props.handleClose();
      props.setReloading(true);
      setTimeout(() => {
        Axios.delete(`${API_URL}/aid/${props.deleteApplicantId}`).then(
          (res) => {
            props.setReloading(false);
            props.handleClose();
          }
        );
      }, 2000);
    } catch (error) {
      props.setReloading(false);
      props.handleClose();
      console.log('ERR', error);
    }
  };
  return (
    <React.Fragment>
      <DialogTitle
        id="lease-addform-title"
        style={{
          backgroundColor: '#1976d2',
          marginBottom: '5px',
          padding: '5px',
          paddingBottom: '3px',
          paddingTop: '2px',
          color: 'white',
          textAlign: 'start',
          fontSize: '17px',
          display: 'flex',
          justifyContent: 'space-between',
          width: 'auto',
        }}
      >
        <Box>
          <WarningRoundedIcon
            sx={{ mr: '3px', pb: '6px', fontSize: '28px' }}
            style={{ color: '#eb4934' }}
          />
          Delete Confirmation
        </Box>
        <CloseIcon
          onClick={() => props.handleClose()}
          sx={{
            cursor: 'pointer',
            color: 'grey.400',
            '&:hover': {
              color: '#f94449',
            },
            fontSize: '21px',
          }}
          id="lease-addform-close-icon"
        />
      </DialogTitle>
      <DialogContent
        sx={{
          pb: 1,
          mt: 1,
        }}
      >
        <DialogContentText id="alert-dialog-description " sx={{ pb: 2 }}>
          <b>{props.deleteApplicantName}</b>
          <br />
          <span>Are you sure want to delete this applicant?</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: '10px' }}>
        <Button
          variant="outlined"
          onClick={() => props.handleClose()}
          id="delete-confirm-no-btn"
          sx={{ textTransform: 'none', border: 'none', p: '2px' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleYes}
          autoFocus
          id="delete-confirm-yes-btn"
          sx={{ textTransform: 'none', p: '1px', fontSize: '13px' }}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
