import { Box, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

const Viewstep3 = (props) => {
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Indian Referral Name 
 
          "
          content={props.currentUserView.ivname}
          md="6"
        />
        <GridTemplete
          title="Indian Referral Email 
 
"
          content={props.currentUserView.ivemail}
          md="6"
        />
        <GridTemplete
          title="Indian Referral Mobile Number 

          "
          content={props.currentUserView.ivphone}
          md="6"
        />

        <GridTemplete
          title="Relationship with Referral 

          "
          content={props.currentUserView.relationtoapplicant}
          md="6"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep3;
