import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Button,
  Grid,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import axios from 'axios';
import { styled } from '@mui/material/styles';
import CustomAmountInput from '../AidProcess/Amount';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '370px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const schema1 = yup.object().shape({
  comments: yup.string().required('Comments are required'),
  notes: yup.string().required('Notes are required'),
  status: yup.string().required('Status is required'),
});
const schema2 = yup.object().shape({
  comments: yup.string().required('Comments are required'),
  notes: yup.string().required('Notes are required'),
  status: yup.string().required('Status is required'),
  approvedAmount: yup.number().required('Amount is required'),
});

const EditAid = (props) => {
  const { userData } = useOutletContext();
  const [menuItems, setMenuItems] = useState([]);
  console.log('Edit Data', props.currentUser?.comments);
  const [state, getState] = useState('');
  console.log('state', state);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(state === 'approved' ? schema2 : schema1),
    // defaultValues: {
    //   comments: props.currentUser?.comments || "",
    //   notes: "",
    //   status: props.currentUser?.status || "",
    // },
  });
  const watchState = watch('status');
  console.log('WATCHSTATE', watchState);
  // getState(watchState);

  useEffect(() => {
    getState(watchState);
  }, [watchState]);
  useEffect(() => {
    const updatedMenuItem = () => {
      const { role } = userData;
      let items = [];
      role.map((role) => {
        if (role === 'committee head') {
          items = ['reviewed', 'onhold', 'rejected', 'approved', 'paid'];
        }
      });
      setMenuItems(items);
    };
    updatedMenuItem();
  }, [userData]);

  useEffect(() => {
    setValue('status', props.currentUser?.status);
    setValue('notes', props.currentUser?.notes);
    setValue('comments', props.currentUser?.comments);
    setValue('approvedAmount', props.currentUser?.approvedAmount);
  }, [props.currentUser]);

  console.log('CU', props.currentUser?.status);

  const API_URL = process.env.React_App_Api;
  const onSubmit = async (data) => {
    try {
      props.setReloading(true);
      setTimeout(async () => {
        const state = {
          status: data.status,
          comments: data.comments,
          committeenotes: data.notes,
          // approvedAmount: data.approvedAmount,
          updatedBy: userData.lastName
            ? `${userData.firstName}${userData.lastName}`
            : userData.firstName,
        };

        if (data.hasOwnProperty('approvedAmount')) {
          state.approvedAmount = data.approvedAmount;
        }

        if (data.status === 'reviewed') {
          state.stage = 'approver';
        } else if (data.status === 'approved') {
          state.stage = 'treasurer';
        } else if (data.status === 'paid') {
          state.stage = 'treasurer';
        }

        const response = await axios.patch(
          `${API_URL}/aid/update/` + props.currentUser?.id,
          state
        );
        console.log('RESPONSEDATA', response.data);
        props.setReloading(false);
        props.setAlert({
          open: true,
          message: 'Status Update Successfully',
          severity: 'success',
        });
      }, 1000);
    } catch (error) {
      console.error('Error updating data:', error);
      props.setReloading(false);
    }
    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="expnses-add-titel"
          style={{
            backgroundColor: '#1976d2',
            marginBottom: '15px',
            padding: '4px',
            color: 'white',
            textAlign: 'center',
            fontSize: '15px',
          }}
        >
          Update Application
          <CloseIcon
            id="expenses-close-icon"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 2,
              marginTop: '1px',
              marginRight: '5px',
              fontSize: '20px',
              color: 'grey.400',
              '&:hover': {
                color: '#f94449',
              },
            }}
          />
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', padding: '8px' }}
            >
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Status</InputLabel>
                    <Controller
                      control={control}
                      name="status"
                      render={({ field }) => (
                        <Select {...field} label="Status" required>
                          {menuItems.map((item) => (
                            <MenuItem key={item} value={item}>
                              {capitalizeFirstLetter(item)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                {watchState === 'approved' ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAmountInput
                      label="Approved Amount"
                      name="approvedAmount"
                      placeholder="Enter Amount"
                      control={control}
                      disabled={
                        props.currentUser?.approvedAmount ? true : false
                      }
                      error={errors.approvedAmount}
                      className="amountInput"
                    />
                  </Grid>
                ) : userData.role === 'dispersor' ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAmountInput
                      label="Approved Amount"
                      name="approvedAmount"
                      placeholder="Enter Amount"
                      control={control}
                      error={errors.approvedAmount}
                      className="amountInput"
                      disabled={true}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="comments"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="update-comments"
                        label="Comments"
                        multiline
                        rows={2}
                        {...field}
                        error={Boolean(errors.comments)}
                        helperText={errors.comments?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Notes"
                        id="update-notes"
                        multiline
                        rows={2}
                        {...field}
                        error={Boolean(errors.notes)}
                        helperText={errors.notes?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mr: 1, p: 1 }}>
            {/* <Button
              autoFocus
              variant="outlined"
              onClick={handleClose}
              id="expenses-cancel-btn"
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button> */}
            <Button
              autoFocus
              variant="contained"
              type="submit"
              id="update-submit-btn"
              sx={{
                textTransform: 'none',
                fontSize: '11px',
                p: '5px',
                backgroundColor: '#1976d2',
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Box>
        {/* </Dialog> */}
      </BootstrapDialog>
    </Fragment>
  );
};

export default EditAid;
