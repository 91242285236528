import React from "react";
import { Box, Grid } from "@mui/material";
import GridTemplate from "./Templete/GridTemplete";

const Viewstep1 = (props) => {
  function DateFormat(dateString) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return dateString;
    }
    // const options = { month: 'short', day: 'numeric', year: 'numeric' };

    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      date
    );
    return `${day}-${month}-${year}`;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={1}>
        {/* Render user information */}
        <GridTemplate
          title="First Name"
          content={props.currentUserView.firstName}
          md="4"
        />
        <GridTemplate
          title="Last Name"
          content={props.currentUserView.lastName}
          md="4"
        />
        <GridTemplate
          title="Date of Birth"
          content={DateFormat(props.currentUserView.dob)}
          md="4"
        />
        <GridTemplate
          title="Email"
          content={props.currentUserView.appemail}
          md="4"
        />
        <GridTemplate
          title="Phone Number"
          content={props.currentUserView.phonenumber}
          md="4"
        />
        <GridTemplate
          title="Address"
          content={props.currentUserView.addressone}
          md="4"
        />
        <GridTemplate
          title="City"
          content={props.currentUserView.city}
          md="4"
        />
        <GridTemplate
          title="State"
          content={props.currentUserView.state}
          md="4"
        />
        <GridTemplate
          title="District"
          content={props.currentUserView.district}
          md="4"
        />
        <GridTemplate
          title="Pincode"
          content={props.currentUserView.pincode}
          md="4"
        />
        <GridTemplate
          title="Aadhar Number"
          content={props.currentUserView.aadharcardnumber}
          md="4"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep1;
