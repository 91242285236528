export const reportfinacalData = [
  {
    id: 20,
    title: 'USA-Volunteer',
    list1:
      'consectetur, adipisicing elit. Perferendis inventore Perferendis inventore',
    path: 'USA-Volunteer Wise Report',
  },
  {
    id: 21,
    title: 'Type',
    list1:
      'consectetur, adipisicing elit. Perferendis inventore Perferendis inventore',
    path: 'Institution Type Wise Report',
  },
  {
    id: 19,
    title: 'District',
    list1:
      'consectetur, adipisicing elit. Perferendis inventore Perferendis inventore',
    path: 'District Wise report',
  },
];
