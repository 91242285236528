import { Button, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/reports`);
  };
  return (
    <div>
      <Button
        color="primary"
        sx={{
          borderRadius: '20px',
          padding: '15px',
          height: '25px',
          px: '10px',
        }}
        onClick={() => handleClick()}
      >
        <KeyboardArrowLeftIcon />{' '}
        <Typography sx={{ ml: '5px', fontSize: '16px', textTransform: 'none' }}>
          Back
        </Typography>
      </Button>
    </div>
  );
};

export default BackButton;
