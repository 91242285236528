import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import Axios from 'axios';
import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridToolbar } from '@mui/x-data-grid';
import EditAid from './EditAid';
import BasicChip from '../../component/display/MuiChip';
import { styled } from '@mui/material/styles';
import HorizontalNonLinearStepper from '../AidProcess/Views/Stepper';
import DashBoardCard from '../Dashboard/status/DashboardCardView';
import Dialog from '@mui/material/Dialog';
import ConfirmDialogModal from './DeleteDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import EditDetails from './editDetails';
import EditDocuments from './EditDocuments';
import SellIcon from '@mui/icons-material/Sell';
import Reports from './Reports';
import Alerts from '../../helper/Alert';

const StyleDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(15),
  },
  '& .MuiDataGrid-footer': {
    borderTop: 'none',
  },
  '& .MuiDataGrid-root': {
    height: '100%', // Setting height to 100% of the container
    overflowY: 'auto', // Allowing vertical scrolling
  },
}));

function Aid(props) {
  const { userData } = useOutletContext();
  function AmountFormat(amount) {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2, // Adjust as needed
    }).format(amount);
  }

  //Change the small letter to CamelCase

  const API_URL = process.env.React_App_Api;
  const [open, setOpen] = useState(false);
  const [detailsFormOpen, setDetailFormOpen] = useState(false);
  const [documentsFormOpen, setDocumentsFormOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);

  const [listall, setListAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [year, setYear] = useState(2024);

  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserView, setCurrentView] = useState({});
  const [currentDetailsValue, setCurrentDetailsValue] = useState({});
  const [currentDocumentsValue, setCurrentDocumentsValue] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteApplicantId, setDeleteApplicantId] = useState('');
  const [deleteApplicantName, setDeleteApplicantName] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const timer = setTimeout(async () => {
        try {
          const response = await Axios.get(`${API_URL}/aid/listall`, {
            params: {
              year: year,
            },
          });
          setListAll(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data:', error);
        }
      }, 1500);
      return () => clearTimeout(timer);
    };
    fetchData();
  }, [reloading, year]);
  const rows =
    listall &&
    listall.map((user) => ({
      id: user._id,
      applicant: user.applicant.firstName + ' ' + user.applicant.lastName,
      city: user.address.city,
      district: user.address.district,
      amtrequested: user.aidinformation.amtrequested.$numberDecimal,
      aidtype: user.aidinformation.aidtype,
      reasonforrequesting: user.aidinformation.reasonforrequesting,
      institutionname: user.institutiondetails.uname,

      indiavolunteer: user.indiavolunteer.ivname,
      usasponsor: user.usavolunteer.usvname,
      notes: user.committeenotes === 'string' ? '' : user.committeenotes,
      // status: user.status === "string" ? "Requested" : user.status,
      stage: user.stage,
      status: user.status,
      createdon: user.createdDt,
      approvedAmount: user.approvedAmount ? user.approvedAmount : null,
      comments: user.comments === 'string' ? '' : user.comments,
    }));

  const handleStatus = (value) => {
    if (value === 'new') {
      return <BasicChip color="primary" label="New" />;
    } else if (value === 'reviewed') {
      return <BasicChip label="Reviewed" color="success" />;
    } else if (value === 'approved') {
      return <BasicChip color="success" label="Approved" />;
    } else if (value === 'paid') {
      return <BasicChip color="success" label="Paid" />;
    } else if (value === 'rejected') {
      return <BasicChip color="error" label="Rejected" />;
    }
  };

  const handleDelete = (details) => {
    setDeleteApplicantId(details.id);
    setDeleteApplicantName(details.applicant);
    setDeleteOpen(true);
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };

  const columns = [
    {
      field: 'applicant',
      headerName: 'Name',
      width: 140,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'city',
      headerName: 'City',
      width: 120,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'district',
      headerName: 'District',
      width: 120,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'amtrequested',
      headerName: 'Amount Requested',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      width: 130,
      renderCell: (params) => AmountFormat(params.row.amtrequested),
    },
    {
      field: 'aidtype',
      headerName: 'Aid Type',
      width: 250,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createdon',
      headerName: 'Submitted Date',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) =>
        dayjs(params.value).format('DD-MMM-YYYY').toUpperCase(),
    },
    {
      field: 'approvedAmount',
      headerName: 'Approved Amount',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => AmountFormat(params.row.approvedAmount),
    },
    {
      field: 'stage',
      headerName: 'Stage',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      align: 'center',
      headerAlign: 'center',
      hideable: false,
      renderCell: (params) => handleStatus(params.value),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideable: false,
      disableRowSelectionOnClick: true,
      disableColumnMenu: true,
      filterable: false,

      renderCell: (params) => {
        return (
          <Stack direction="row">
            <IconButton
              color="primary"
              disabled={params.row.status === 'paid' ? true : false}
              sx={{ ml: '3px' }}
            >
              <Tooltip title={`Update Status`}>
                <EditIcon
                  fontSize="small"
                  onClick={() => handleEdit(params.row)}
                  id="table-edit-icon"
                />
              </Tooltip>
            </IconButton>
            <IconButton
              color="primary"
              disabled={params.row.status === 'paid' ? true : false}
              sx={{ ml: '3px' }}
            >
              <Tooltip title={`Update Details`}>
                <SellIcon
                  fontSize="small"
                  onClick={() => handleEditDetailsForm(params.row.id)}
                  id="table-edit-icon"
                />
              </Tooltip>
            </IconButton>
            <IconButton
              color="primary"
              disabled={params.row.status === 'paid' ? true : false}
              sx={{ ml: '3px' }}
            >
              <Tooltip title={`Update Documents`}>
                <SaveAsIcon
                  fontSize="small"
                  onClick={() => handleEditDocument(params.row.id)}
                  id="table-edit-icon"
                />
              </Tooltip>
            </IconButton>

            <IconButton color="success">
              <Tooltip title={`View Details`}>
                <VisibilityIcon
                  onClick={() => handleView(params.row.id)}
                  id="view-icon-btn"
                />
              </Tooltip>
            </IconButton>
            <IconButton
              color="error"
              disabled={
                params.row.status === 'paid' || params.row.status === 'approved'
                  ? true
                  : false
              }
            >
              <Tooltip title={`Delete Applicant`}>
                <DeleteIcon
                  onClick={() => handleDelete(params.row)}
                  id="view-icon-btn"
                />
              </Tooltip>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const handleEdit = (row) => {
    setCurrentUser(row);
    setOpen(true);
  };

  const [documentloading, setDocumentLoading] = useState(false);
  const handleEditDocument = async (id) => {
    setDocumentLoading(true);
    try {
      const user = await Axios.get(`${API_URL}/aid/${id}`);
      console.log('allValue', user);

      setCurrentDocumentsValue({
        id: user.data._id,
        firstName: user.data.applicant.firstName,
        lastName: user.data.applicant.lastName,
        aadharcardnumber: user.data.applicant.aadharcardnumber,
        aadharCardFront: user.data.applicant.aadharCardFront,
        aadharCardBack: user.data.applicant.aadharCardBack,
        passportSizePhotoFile: user.data.applicant.passportSizePhotoFile,
        marksheetsFile: user.data.applicant.marksheetsFile,
        bonafideCertificate: user.data.institutiondetails.bonafideCertificate,
        proofOfIncomeFile: user.data.parentorguardian.proofOfIncomeFile,
        usaReferenceLetter: user.data.usavolunteer.usaReferenceLetter,
        msetScholarshipLetter: user.data.msetScholarshipLetter
          ? user.data.msetScholarshipLetter
          : '',
      });
      setDocumentsFormOpen(true);
      setDocumentLoading(false);
      // setCurrentUser(row);
    } catch (err) {
      console.error('Failed to get data: ' + err);
    }
  };

  const handleEditDetailsForm = async (id) => {
    try {
      const user = await Axios.get(`${API_URL}/aid/${id}`);

      setCurrentDetailsValue({
        id: user.data._id,
        reviewcomments: user.data.reviewcomments,

        financecommitteenotes: user.data.financecommitteenotes,
        username: user.data.username,
        submittername: user.data.submittername,
        submitterphonenumber: user.data.submitterphonenumber,
        // dob: user.data.applicant.dob,
        dob: user.data.applicant.dob,
        aadharcardnumber: user.data.applicant.aadharcardnumber,
        appemail: user.data.applicant.appemail,
        aadharCardFront: user.data.applicant.aadharCardFront,
        aadharCardBack: user.data.applicant.aadharCardBack,
        passportSizePhotoFile: user.data.applicant.passportSizePhotoFile,
        marksheetsFile: user.data.applicant.marksheetsFile,
        bonafideCertificate: user.data.institutiondetails.bonafideCertificate,
        proofOfIncomeFile: user.data.parentorguardian.proofOfIncomeFile,
        usaReferenceLetter: user.data.usavolunteer.usaReferenceLetter,
        phonenumber: user.data.applicant.phonenumber,
        firstName: user.data.applicant.firstName,
        lastName: user.data.applicant.lastName,
        city: user.data.address.city,
        state: user.data.address.state,
        district: user.data.address.district,
        addressone: user.data.address.addressone,
        pincode: user.data.address.pincode,
        answer: user.data.questions.answer,
        fathername: user.data.parentorguardian.fathername,
        fatherprofession: user.data.parentorguardian.fatherprofession,
        mothername: user.data.parentorguardian.mothername,
        motherprofession: user.data.parentorguardian.motherprofession,
        familyincomepm: user.data.parentorguardian.familyincomepm,
        uname: user.data.institutiondetails.name,
        unmobileNumber: user.data.institutiondetails.mobileNumber,
        unemail: user.data.institutiondetails.email,
        unaddress: user.data.institutiondetails.address,
        uncity: user.data.institutiondetails.city,
        unstate: user.data.institutiondetails.state,
        unpincode: user.data.institutiondetails.pincode,
        idphone: user.data.institutiondetails.idphone,
        website: user.data.institutiondetails.website,
        ifsccode: user.data.paymentmethod.ifsccode,
        accountnumber: user.data.paymentmethod.accountnumber,
        branchname: user.data.paymentmethod.branchname,
        paymentaddress: user.data.paymentmethod.branchAddress,
        beneficiaryname: user.data.paymentmethod.beneficiaryname,
        beneficiaryphone: user.data.paymentmethod.beneficiaryphone,
        bankname: user.data.paymentmethod.bankname,
        amtrequested: user.data.aidinformation.amtrequested.$numberDecimal,
        aidtype: user.data.aidinformation.aidtype,
        multipleyears: user.data.aidinformation.multipleyears,
        yearsofsupportisneeded: user.data.aidinformation.yearsofsupportisneeded,
        reasonforrequesting: user.data.aidinformation.reasonforrequesting,
        // uname: user.data.institutiondetails.uname,
        ivname: user.data.indiavolunteer.ivname,
        ivphone: user.data.indiavolunteer.ivphone,
        ivemail: user.data.indiavolunteer.ivemail,

        relationtoapplicant: user.data.indiavolunteer.relationtoapplicant,
        usvname: user.data.usavolunteer.usvname,
        usvphone: user.data.usavolunteer.usvphone,
        usvemail: user.data.usavolunteer.email,
        committeenotes: user.data.committeenotes,
        comments: user.data.comments,
        status: user.data.status,
      });
      setDetailFormOpen(true);
      // setCurrentUser(row);
    } catch (err) {
      console.error('Failed to get data: ' + err);
    }
  };

  // const handleView = (row) => {
  //   setCurrentUser(row);
  //   setViews(row._id);
  //   setOpen(true);
  // };
  const handleView = async (id) => {
    try {
      const user = await Axios.get(`${API_URL}/aid/${id}`);
      // setCurrentView(user.data);
      setCurrentView({
        id: user.data._id,
        reviewcomments: user.data.reviewcomments,

        financecommitteenotes: user.data.financecommitteenotes,
        username: user.data.username,
        submittername: user.data.submittername,
        submitterphonenumber: user.data.submitterphonenumber,
        // dob: user.data.applicant.dob,
        dob: user.data.applicant.dob,
        aadharcardnumber: user.data.applicant.aadharcardnumber,
        appemail: user.data.applicant.appemail,
        aadharCardFront: user.data.applicant.aadharCardFront,
        aadharCardBack: user.data.applicant.aadharCardBack,
        passportSizePhotoFile: user.data.applicant.passportSizePhotoFile,
        marksheetsFile: user.data.applicant.marksheetsFile,
        bonafideCertificate: user.data.institutiondetails.bonafideCertificate,
        proofOfIncomeFile: user.data.parentorguardian.proofOfIncomeFile,
        usaReferenceLetter: user.data.usavolunteer.usaReferenceLetter,
        phonenumber: user.data.applicant.phonenumber,
        firstName: user.data.applicant.firstName,
        lastName: user.data.applicant.lastName,
        city: user.data.address.city,
        state: user.data.address.state,
        district: user.data.address.district,
        addressone: user.data.address.addressone,
        pincode: user.data.address.pincode,
        answer: user.data.questions.answer,
        fathername: user.data.parentorguardian.fathername,
        fatherprofession: user.data.parentorguardian.fatherprofession,
        mothername: user.data.parentorguardian.mothername,
        motherprofession: user.data.parentorguardian.motherprofession,
        familyincomepm: user.data.parentorguardian.familyincomepm,
        uname: user.data.institutiondetails.name,
        unmobileNumber: user.data.institutiondetails.mobileNumber,
        unemail: user.data.institutiondetails.email,
        unaddress: user.data.institutiondetails.address,
        uncity: user.data.institutiondetails.city,
        unstate: user.data.institutiondetails.state,
        unpincode: user.data.institutiondetails.pincode,
        idphone: user.data.institutiondetails.idphone,
        website: user.data.institutiondetails.website,
        ifsccode: user.data.paymentmethod.ifsccode,
        accountnumber: user.data.paymentmethod.accountnumber,
        branchname: user.data.paymentmethod.branchname,
        paymentaddress: user.data.paymentmethod.branchAddress,
        beneficiaryname: user.data.paymentmethod.beneficiaryname,
        beneficiaryphone: user.data.paymentmethod.beneficiaryphone,
        bankname: user.data.paymentmethod.bankname,
        amtrequested: user.data.aidinformation.amtrequested.$numberDecimal,
        aidtype: user.data.aidinformation.aidtype,
        multipleyears: user.data.aidinformation.multipleyears,
        yearsofsupportisneeded: user.data.aidinformation.yearsofsupportisneeded,
        reasonforrequesting: user.data.aidinformation.reasonforrequesting,
        // uname: user.data.institutiondetails.name,
        ivname: user.data.indiavolunteer.ivname,
        ivphone: user.data.indiavolunteer.ivphone,
        ivemail: user.data.indiavolunteer.ivemail,

        msetScholarshipLetter: user.data.msetScholarshipLetter
          ? user.data.msetScholarshipLetter
          : '',

        relationtoapplicant: user.data.indiavolunteer.relationtoapplicant,
        usvname: user.data.usavolunteer.usvname,
        usvphone: user.data.usavolunteer.usvphone,
        usvemail: user.data.usavolunteer.email,
        committeenotes: user.data.committeenotes,
        comments: user.data.comments,
        status: user.data.status,
      });
      setViewOpen(true);
    } catch (err) {
      console.error('Failed to get data: ' + err);
    }
  };
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <Box>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <Dialog open={deleteOpen} onClose={handleClose}>
        <ConfirmDialogModal
          deleteApplicantId={deleteApplicantId}
          deleteApplicantName={deleteApplicantName}
          handleClose={handleClose}
          setReloading={setReloading}
        />
      </Dialog>
      <HorizontalNonLinearStepper
        viewOpen={viewOpen}
        setViewOpen={setViewOpen}
        currentUserView={currentUserView}
      />

      <EditAid
        open={open}
        setOpen={setOpen}
        currentUser={currentUser}
        setReloading={setReloading}
        setAlert={setAlert}
      />

      <EditDetails
        open={detailsFormOpen}
        setOpen={setDetailFormOpen}
        currentDetailsValue={currentDetailsValue}
        setReloading={setReloading}
        setAlert={setAlert}
      />

      <EditDocuments
        open={documentsFormOpen}
        setOpen={setDocumentsFormOpen}
        currentDetailsValue={currentDocumentsValue}
        setReloading={setReloading}
        handleEditDocument={handleEditDocument}
        documentloading={documentloading}
        setAlert={setAlert}
      />
      <DashBoardCard openDrawer={props.openDrawer} reloading={reloading} />
      {/* <div style={{ height: 500, width: "60%", overflow: "auto" }}> */}
      <Box sx={{ marginTop: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '17px', ml: 1 }}>
            Application Details - {year}
          </Typography>
          <Box sx={{ mr: 1 }}>
            <Button
              variant="contained"
              sx={{ p: '3px' }}
              onClick={() => setYear(2023)}
            >
              2023
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 1, p: '3px' }}
              onClick={() => setYear(2024)}
            >
              2024
            </Button>
          </Box>
        </Box>
        <Grid container md={12} style={{ height: '75vh', width: '100%' }}>
          <StyleDataGrid
            density="compact"
            getRowHeight={() => 'auto'}
            components={{
              Toolbar: GridToolbar,
            }}
            pagination
            paginationMode="server"
            loading={loading || reloading}
            getRowClassName="blue-header"
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Grid>
      </Box>
    </Box>
  );
}

export default Aid;
