import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularDeterminate({ sx, data, icon }) {
  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <div style={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={data}
          style={{ ...sx }} // Applying custom style here
        />
        {icon}
      </div>
    </Stack>
  );
}
