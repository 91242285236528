import { Box, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

const Viewstep4 = (props) => {
  console.log("curretValue", props.currentUserView);
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Volunteer Name 

 
 
          "
          content={props.currentUserView.usvname}
          md="6"
        />
        <GridTemplete
          title="Volunteer Email 
        
 
"
          content={props.currentUserView.usvemail}
          md="6"
        />
        <GridTemplete
          title="Volunteer Mobile Number 
 

          "
          content={props.currentUserView.usvphone}
          md="6"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep4;
