import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import BackButton from '../../component/BackButton';

function ReportsDetail() {
  const { id } = useParams();
  const location = useLocation();
  const { reports } = location.state;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <BackButton />
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 20 }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <iframe
          src={reports}
          title={`Report ${id}`}
          width="100%"
          height="500px"
          allowTransparency
        />
      )}
    </div>
  );
}

export default ReportsDetail;
