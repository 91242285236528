import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

const Viewstep7 = (props) => {
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Account Number 
          "
          content={props.currentUserView.accountnumber}
          md="4"
        />
        <GridTemplete
          title="IFSC Code 
          "
          content={props.currentUserView.ifsccode}
          md="4"
        />
        <GridTemplete
          title="Bank Name 
          "
          content={props.currentUserView.bankname}
          md="4"
        />
        <GridTemplete
          title="Branch Name 

          "
          content={props.currentUserView.branchname}
          md="4"
        />
        <GridTemplete
          title="Branch Address 
 
          "
          content={props.currentUserView.paymentaddress}
          md="4"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep7;
