import Grid from '@mui/material/Grid';

import { Box, Button } from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import IntPhoneNumberField from '../../../component/form/IntPhoneNumber';
import TextFields from '../../../component/form/TextFields';
import React from 'react';

const schema = yup.object().shape({
  institutionName: yup
    .string()
    .required('Institution Name is required')
    .max(80, 'Institution Name must be at most 80 characters')
    .matches(/^[A-Za-z\s]+$/, 'Institution Name must be alphabets only'),
  institutionEmail: yup
    .string()
    .email('Invalid email')
    .required('This filed is required'),
  institutionMobileNumber: yup
    .string()
    .required('Indian Referral Mobile Number is required'),
  institutionAddress: yup.string().required('Institution Address is required'),

  institutionCity: yup
    .string()
    .required('City is required')
    .max(30, 'City must be at most 30 characters')
    .matches(/^[A-Za-z\s]+$/, 'city must be alphabets only'),

  institutionZip: yup
    .string()
    .required('Zip code is required')
    .matches(/^[0-9]{6}$/, 'Zip code must be exactly 6 digits'),

  beneficiaryPhone: yup.string().notRequired(), // Making the field optional,
  // .typeError("Volunteer Phone must be a number")
  // .matches(/^\d+$/, "Volunteer Phone must contain only numbers")
  // .max(10, "Volunteer Phone must be less than or equal to 10 numbers"),
  beneficiaryName: yup
    .string()
    .required('Volunteer Name is required')
    .max(30, 'Volunteer Name must be less than 30 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Volunteer Name must be only letters'),

  // bonafideCertificate: yup.mixed().notRequired(),
});

export default function Institution({
  // formData,
  currentDetailsValue,
  setGetValue,
  handleNext,
  getValue,
  // handleBack,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: formData,

    defaultValues: {
      institutionName: getValue?.institutionName || currentDetailsValue?.uname,
      institutionMobileNumber:
        getValue?.institutionMobileNumber ||
        currentDetailsValue?.unmobileNumber,
      institutionEmail:
        getValue?.institutionEmail || currentDetailsValue?.unemail,
      institutionAddress:
        getValue?.institutionAddress || currentDetailsValue?.unaddress,
      institutionCity: getValue?.institutionCity || currentDetailsValue?.uncity,

      institutionZip:
        getValue?.institutionZip || currentDetailsValue?.unpincode,

      beneficiaryName:
        getValue?.beneficiaryName || currentDetailsValue?.beneficiaryname,

      beneficiaryPhone:
        getValue?.beneficiaryPhone || currentDetailsValue?.beneficiaryPhone,
    },
  });
  console.log('getValue', getValue);
  // useEffect(() => {
  //   setValue("institutionName", currentDetailsValue?.uname);
  //   // setValue('area', PropertyDetails.propertyDetail.area);
  //   // setValue('lotarea', PropertyDetails.propertyDetail.lotArea);
  //   // setValue('bedrooms', PropertyDetails.propertyDetail.bedRooms);
  //   // setValue('bathrooms', PropertyDetails.propertyDetail.bathRooms);
  //   // setValue('storey', PropertyDetails.propertyDetail.stoery);
  //   // setValue('ownership', PropertyDetails.propertyDetail.ownerShip);
  //   // setValue('hometype', PropertyDetails.propertyDetail.homeType);
  //   // setValue('homevalue', PropertyDetails.propertyDetail.homeValue);
  //   // setValue('taxassessment', PropertyDetails.propertyDetail.taxAssessment);
  // }, [currentDetailsValue]);

  const onSubmit = (data) => {
    console.log('value submit', data);
    setGetValue(data);
    handleNext();
  };

  return (
    <>
      <Grid
        component="form"
        noValidate
        container
        spacing={2}
        p={2}
        sx={{
          display: 'flex',
        }}
      >
        <Grid item md={6} sm={12}>
          <TextFields
            control={control}
            name="institutionName"
            label={
              <label>
                Institution Name<span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            placeholder=""
            error={errors.institutionName}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextFields
            control={control}
            name="institutionEmail"
            label={
              <label>
                Institution Email<span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            placeholder=""
            error={errors.institutionEmail}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <IntPhoneNumberField
            control={control}
            name="institutionMobileNumber"
            label={
              <label>
                Institution Mobile Number
                <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            error={errors.institutionMobileNumber}
          />
        </Grid>

        <Grid item md={6} sm={12}>
          <TextFields
            control={control}
            name="institutionAddress"
            label={
              <label>
                Institution Address{' '}
                <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            placeholder=""
            error={errors.institutionAddress}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextFields
            control={control}
            name="institutionCity"
            label={
              <label>
                City <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            placeholder=""
            error={errors.institutionCity}
          />
        </Grid>

        <Grid item md={6} sm={12}>
          <TextFields
            control={control}
            name="institutionZip"
            label={
              <label>
                PIN Code <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            placeholder=""
            error={errors.institutionZip}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextFields
            control={control}
            name="beneficiaryName"
            label={
              <label>
                Beneficiary Name <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            placeholder=""
            error={errors.beneficiaryName}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <IntPhoneNumberField
            control={control}
            name="beneficiaryPhone"
            label=" Beneficiary Mobile Number(optional)"
            error={errors.beneficiaryPhone}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'end', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={handleSubmit(onSubmit)} sx={{ textTransform: 'none' }}>
          Next
        </Button>
      </Box>
    </>
  );
}
