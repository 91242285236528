import { Box, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

// import GridTemplete from "./Templete/GridTemplete";

const Viewstep9 = (props) => {
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Notes

          "
          content={props.currentUserView.committeenotes}
          md="12"
        />
        <GridTemplete
          title="Comments

 
"
          content={props.currentUserView.comments}
          md="12"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep9;
