import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
  CircularProgress,
} from '@mui/material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
// import "../../../../style/viewImag.css";
import '../../style/viewImag.css';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const EditDocuments = (props) => {
  const [s3Images, setS3Images] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [updateImage, setUpdateImage] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);
  const [image, setImage] = useState(null);
  const inputRef = useRef(null);

  const BUCKET_REGION = process.env.REACT_APP_BUCKET_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  useEffect(() => {
    const fetchSignedUrl = async () => {
      try {
        const s3Client = new S3Client({
          region: BUCKET_REGION,
          credentials: {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
          },
        });

        const filesData = [
          props.currentDetailsValue.aadharCardFront,
          props.currentDetailsValue.aadharCardBack,
          props.currentDetailsValue.passportSizePhotoFile,
          props.currentDetailsValue.marksheetsFile,
          props.currentDetailsValue.proofOfIncomeFile,
          props.currentDetailsValue.usaReferenceLetter,
          props.currentDetailsValue.bonafideCertificate,
          props.currentDetailsValue.msetScholarshipLetter &&
            props.currentDetailsValue.msetScholarshipLetter,
        ];

        const updatedS3Images = await Promise.all(
          filesData.map(async (image) => {
            if (image) {
              const segments = image.split('/');
              const bucketName = segments[2];
              const subdomain = bucketName.split('.');
              const bucket = subdomain[0];
              const folder = segments.slice(3, segments.length - 1).join('/');
              const filename = segments[segments.length - 1];
              const params = {
                Bucket: bucket,
                Key: folder ? `${folder}/${filename}` : filename,
              };
              const signedUrl = await getSignedUrl(
                s3Client,
                new GetObjectCommand(params)
              );
              return signedUrl;
            }
            return null;
          })
        );
        // setS3Images(filesData.filter((image) => image !== null));
        setS3Images(updatedS3Images.filter((image) => image !== null));
      } catch (error) {
        console.error('Error fetching signed URLs:', error);
      }
    };

    fetchSignedUrl();
  }, [props.currentDetailsValue]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? s3Images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setImage(null);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === s3Images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setImage(null);
  };

  const renderCarouselContent = () => {
    const currentImage = s3Images[currentIndex];

    if (!currentImage) {
      return null;
    }

    if (
      typeof currentImage === 'string' &&
      currentImage.startsWith(
        'https://ideausa-docs.s3.ap-south-1.amazonaws.com'
      )
      // currentImage.startsWith('https://ideausa.s3.us-east-1.amazonaws.com')
    ) {
      return renderS3FileContent(currentImage);
    } else {
      return renderFileContent(currentImage);
    }
  };

  const renderFileContent = (currentFile) => {
    console.log('renderFileContent', currentFile);
    const url = currentFile ? URL.createObjectURL(currentFile) : null;
    if (currentFile.type.startsWith('image')) {
      return (
        <img
          src={url}
          alt={url}
          width="350"
          height="270"
          onClick={() => window.open(url, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      );
    } else if (currentFile.type === 'application/pdf') {
      return (
        <iframe
          src={url}
          title="PDF Viewer"
          width="350"
          height="270"
          onClick={() => window.open(url, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };

  const renderS3FileContent = () => {
    // const url = image ? URL.createObjectURL(image) : s3Images[currentIndex];
    // if (!url) return null;
    const url = s3Images[currentIndex];
    console.log('URL', url);
    const fileExtension = url ? url.split('.').pop().toLowerCase() : null;
    const fileType = fileExtension ? fileExtension.split('?')[0] : null;
    if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
      return (
        <img
          src={url}
          alt={url}
          width="350"
          height="270"
          onClick={() => window.open(url, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      );
    } else if (fileType === 'pdf') {
      return (
        <iframe
          src={url}
          title="PDF Viewer"
          width="350"
          height="270"
          onClick={() => window.open(url, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      );
    } else {
      return null;
    }
  };

  const name = [
    'Aadhar Card Front Side Image',
    'Aadhar Card Back Side Image',
    'Passport Size Photo',
    'Marksheet',
    'Proof of Income',
    'Reference Letter',
    'Bonafide Certificate',
    'MSET Scholarship Letter',
  ];

  const fileName = [
    'aadharCardFront',
    'aadharCardBack',
    'passportSizePhotoFile',
    'marksheetsFile',
    'proofOfIncomeFile',
    'usaReferenceLetter',
    'bonafideCertificate',
    'msetScholarshipLetter',
  ];
  const [currentIndexName, setCurrentIndexName] = useState(null);
  useEffect(() => {
    const currentIndexValue = fileName[currentIndex];
    setCurrentIndexName(currentIndexValue);
    console.log('currentindex value', currentIndexValue);
  }, [updateImage]);
  const API_URL = process.env.React_App_Api;

  const onSubmit = async () => {
    setUpdateImage(false);
    setCircularProgress(true);
    try {
      const fileData = new FormData();
      fileData.append('file', image);
      const folderName = `${props.currentDetailsValue?.firstName}${
        props.currentDetailsValue?.lastName
      }${props.currentDetailsValue?.aadharcardnumber?.slice(-4)}`;
      fileData.append('folderName', folderName);
      fileData.append('fileField', currentIndexName);
      if (image) {
        await axios
          .post(
            `${API_URL}/file/updatefile/${props.currentDetailsValue.id}`,
            fileData
          )
          .then((res) => {
            setTimeout(() => {
              props.handleEditDocument(props.currentDetailsValue.id);
              setCircularProgress(false);
              props.setAlert({
                open: true,
                message: 'Document Update Successfully',
                severity: 'success',
              });
              setUpdateImage(false);
            }, 2000);
          })
          .catch((error) => {
            console.log('ERROR', error);
            let errorMessage =
              'Sorry! Something went wrong please try again later';
            if (error.response && error.response.status === 404) {
              errorMessage = error.response.statusText;
              return props.setAlert({
                open: true,
                message: `Sorry! ${errorMessage} in S3`,
                severity: 'error',
              });
            }
            props.setAlert({
              open: true,
              message: errorMessage,
              severity: 'error',
            });
          });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 503) {
        return props.setAlert({
          open: true,
          message: 'Database is down. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (error.response) {
        return props.setAlert({
          open: true,
          message:
            error.response.data ||
            'An unexpected error occurred While uploading file. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (error.message) {
        return props.setAlert({
          open: true,
          message: error.message || 'Server Error.Please try again later',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        // Handle other errors
        return props.setAlert({
          open: true,
          message: 'An unexpected error occurred. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    }
  };

  const handleImageClick = () => {
    inputRef.current.click();
    setUpdateImage(true);
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);

    const updatedImages = [...s3Images];
    updatedImages[currentIndex] = selectedImage;
    setS3Images(updatedImages);
  };

  const handleClose = () => {
    setImage(null);
    setS3Images([]);
    props.setOpen(false);
  };

  return (
    <Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="expnses-add-titel"
          style={{
            backgroundColor: '#1976d2',
            marginBottom: '15px',
            padding: '4px',
            color: 'white',
            textAlign: 'center',
            fontSize: '15px',
          }}
        >
          Update Application
          <CloseIcon
            id="expenses-close-icon"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 2,
              marginTop: '1px',
              marginRight: '5px',
              fontSize: '20px',
              color: 'grey.400',
              '&:hover': {
                color: '#f94449',
              },
            }}
          />
        </DialogTitle>
        <Box>
          <DialogContent dividers>
            <Box
              sx={{
                textAlign: 'center',
                mx: 'auto',
                p: 3,
                pt: 5,
              }}
            >
              {s3Images.length > 0 && (
                <>
                  <Grid container justifyContent="center" alignItems="center">
                    <IconButton onClick={goToPrevious}>
                      <ArrowCircleLeftOutlinedIcon />
                    </IconButton>
                    {renderCarouselContent()}
                    <IconButton onClick={goToNext}>
                      <ArrowCircleRightOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p style={{ paddingTop: '15px', color: '#1976d2' }}>
                      {' '}
                      {currentIndex + 1}: {name[currentIndex]}
                    </p>

                    <div>
                      {updateImage && image ? (
                        <Box>
                          <IconButton
                            sx={{
                              mt: '14px',
                              bgcolor: '#169E49',
                              p: '2px',
                              height: '30px',
                              width: '30px',
                              mr: '20px',
                            }}
                          >
                            <Tooltip title={`Upload image`}>
                              <UploadIcon
                                sx={{ color: 'white' }}
                                fontSize="small"
                                onClick={onSubmit}
                                id="table-edit-icon"
                              />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      ) : circularProgress ? (
                        <Box
                          sx={{
                            display: 'flex',
                            mt: '22px',
                            mr: '23px',
                          }}
                        >
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        <IconButton
                          sx={{
                            mt: '14px',
                            bgcolor: '#1976D2',
                            p: '2px',
                            height: '30px',
                            width: '30px',
                            mr: '20px',
                          }}
                        >
                          <Tooltip title={`Update image`}>
                            <EditIcon
                              sx={{ color: 'white' }}
                              fontSize="small"
                              onClick={handleImageClick}
                              id="table-edit-icon"
                            />
                          </Tooltip>
                        </IconButton>
                      )}

                      <input
                        type="file"
                        ref={inputRef}
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </Box>
                </>
              )}
            </Box>
          </DialogContent>
        </Box>
      </BootstrapDialog>
    </Fragment>
  );
};

export default EditDocuments;
