import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularDeterminate from "./CirculeBar";
function AmountFormat(amount) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2, // Adjust as needed
  }).format(amount);
}

const DashCardTemplete = (props) => {
  return (
    <div>
      <Box
        sx={{
          p: 2,
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        <Box sx={{ my: "auto", pr: 2 }}>
          <CircularDeterminate
            data={props.total ? props.total : 0}
            icon={
              <Box>
                {props.icon &&
                  React.cloneElement(props.icon, {
                    sx: {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      color: props.color,
                      transform: "translate(-50%, -50%)",
                    },
                  })}
              </Box>
            }
            sx={{ color: props.color }}
          />
        </Box>
        <Box sx={{ ml: 0, borderRight: "1px solid #e6e4dc", pr: 2 }}>
          <Typography sx={{ fontSize: "16px" }}>{props.title}</Typography>
          <Typography sx={{ fontSize: "14px", color: "rgb(145, 158, 171)" }}>
            {props.count ? props.count : 0}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {AmountFormat(props.amount ? props.amount : 0)}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default DashCardTemplete;
