import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import '../../../../style/viewImag.css';

const Viewstep8 = (props) => {
  const [s3Images, setS3Images] = useState([]);
  console.log('mset', props.currentUserView.msetScholarshipLetter);
  const [currentIndex, setCurrentIndex] = useState(0);

  // const BUCKET_REGION = "us-east-1";
  // const ACCESS_KEY = "AKIAWUANKMAPNLPCJLEE";
  // const SECRET_ACCESS_KEY = "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";
  const BUCKET_REGION = process.env.REACT_APP_BUCKET_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  useEffect(() => {
    const fetchSignedUrl = async () => {
      try {
        const s3Client = new S3Client({
          region: BUCKET_REGION,
          credentials: {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
          },
        });

        const filesData = [
          props.currentUserView.aadharCardFront,
          props.currentUserView.aadharCardBack,
          props.currentUserView.passportSizePhotoFile,
          props.currentUserView.marksheetsFile,
          props.currentUserView.proofOfIncomeFile,
          props.currentUserView.usaReferenceLetter,
          props.currentUserView.bonafideCertificate,
          props.currentUserView.msetScholarshipLetter,
        ];

        const updatedS3Images = await Promise.all(
          filesData.map(async (image) => {
            if (image) {
              const segments = image.split('/');
              const bucketName = segments[2];
              const subdomain = bucketName.split('.');
              const bucket = subdomain[0];
              const folder = segments.slice(3, segments.length - 1).join('/');
              const filename = segments[segments.length - 1];
              const params = {
                Bucket: bucket,
                Key: folder ? `${folder}/${filename}` : filename,
              };
              const signedUrl = await getSignedUrl(
                s3Client,
                new GetObjectCommand(params)
              );
              return signedUrl;
            }
            return null;
          })
        );
        // setS3Images(filesData.filter((image) => image !== null));
        setS3Images(updatedS3Images.filter((image) => image !== null));
      } catch (error) {
        console.error('Error fetching signed URLs:', error);
      }
    };

    fetchSignedUrl();
  }, [props.currentUserView]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? s3Images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === s3Images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const renderFileContent = () => {
    console.log('rendor file is ex ');

    const url = s3Images[currentIndex];
    console.log('URL', url);
    const fileExtension = url ? url.split('.').pop().toLowerCase() : null;
    const fileType = fileExtension ? fileExtension.split('?')[0] : null;
    // const segments = url.split('/');
    // const fileName = segments[segments.length - 1];
    // const fileType = fileName.split('.').pop();
    console.log('file', fileType);
    if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
      return (
        <img
          src={url}
          alt={url}
          width="350"
          height="270"
          onClick={() => window.open(url, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      );
    } else if (fileType === 'pdf') {
      return (
        <iframe
          src={url}
          title="PDF Viewer"
          width="350"
          height="270"
          onClick={() => window.open(url, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      );
    } else {
      return null;
    }
  };

  const name = [
    'Aadhar Card Front Side Image',
    'Aadhar Card Back Side Image',
    'Passport Size Photo',
    'Marksheet',
    'Proof of Income',
    'Reference Letter',
    'Bonafide Certificate',
    'MSET Scholarship Letter',
  ];

  return (
    <Box
      sx={{
        textAlign: 'center',
        // mx: "auto",
        p: 3,
        pt: 5,
      }}
    >
      {s3Images.length > 0 && (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={goToPrevious}>
              <ArrowCircleLeftOutlinedIcon />
            </IconButton>
            {renderFileContent()}
            <IconButton onClick={goToNext}>
              <ArrowCircleRightOutlinedIcon />
            </IconButton>
          </Grid>
          <p style={{ paddingTop: '15px', color: '#1976d2' }}>
            {' '}
            {currentIndex + 1}: {name[currentIndex]}
          </p>
        </>
      )}
    </Box>
  );
};

export default Viewstep8;
