import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Button } from "react-bootstrap";
import Viewstep1 from "./components/Viewstep1";
import Viewstep2 from "./components/Viewstep2";
import Viewstep3 from "./components/Viewstep3";
import Viewstep4 from "./components/Viewstep4";
import Viewstep5 from "./components/Viewstep5";
import Viewstep6 from "./components/Viewstep6";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import Viewstep7 from "./components/Viewstep7";
import Viewstep8 from "./components/Viewstep9";
import Viewstep9 from "./components/Viewstep8";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const HorizontalNonLinearStepper = (props) => {
  console.log("Current User", props.currentUserView);
  const [value, setValue] = useState(1);
  console.log("VALUE", value);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    { id: 1, step: 1, title: "Applicant Details" },
    { id: 2, step: 2, title: "Parent/Guardian Details" },
    { id: 3, step: 3, title: "India Reference Details" },
    { id: 4, step: 4, title: "USA Volunteer Details" },
    { id: 5, step: 5, title: "Aid Request" },
    { id: 6, step: 6, title: "Instutution Details" },
    { id: 7, step: 7, title: "Bank Details" },
    { id: 8, step: 8, title: "Notes & Comments" },
    { id: 9, step: 9, title: "Documents" },
  ];

  const getTitle = (id) => {
    const step = steps.find((step) => step.id === id);
    return step ? step.title : "";
  };

  return (
    // <Dialog
    //   open={props.viewOpen}
    //   onClose={() => props.setViewOpen(false)}
    //   aria-labelledby="responsive-dialog-title"
    //   maxWidth="sm"
    //   fullScreen={fullScreen}
    //   sx={{
    //     margin: "auto",
    //   }}
    // >
    <BootstrapDialog
      // onClose={handleClose}
      onClose={() => props.setViewOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={props.viewOpen}
      maxWidth="sm"
    >
      <DialogTitle
        id="expnses-add-titel"
        style={{
          backgroundColor: "#1976d2",
          // marginBottom: "15px",
          padding: "4px",
          color: "white",
          textAlign: "center",
          fontSize: "15px",
        }}
      >
        View Application
        <CloseIcon
          id="expenses-close-icon"
          onClick={() => props.setViewOpen(false)}
          sx={{
            position: "absolute",
            right: 2,
            marginTop: "1px",
            marginRight: "5px",
            fontSize: "20px",
            color: "grey.400",
            "&:hover": {
              color: "#f94449",
            },
          }}
        />
      </DialogTitle>
      <Box sx={{ padding: "20px" }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4.5} sx={{ padding: "0px" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "end",
                  mt: "5px",
                  color: "#1976d2",
                }}
              >
                {value && getTitle(value)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7.5}>
            <Box sx={{ textAlign: "end" }}>
              {steps.map((item) => (
                <Tooltip
                  key={item.id}
                  title={item.title}
                  placement="right"
                  arrow
                >
                  <Button
                    onClick={() => setValue(item.id)}
                    id="view-change-step-btn"
                    style={{
                      marginLeft: "5px",
                      backgroundColor: value === item.id ? "gray" : "#1976d2",
                      border: "none",
                      borderRadius: "100%",
                      fontSize: "13px",
                      "&:focus": {
                        backgroundColor: "gray",
                      },
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    {item.step}
                  </Button>
                </Tooltip>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {value === 1 && (
              <Viewstep1 currentUserView={props.currentUserView} />
            )}
            {value === 2 && (
              <Viewstep2 currentUserView={props.currentUserView} />
            )}
            {value === 3 && (
              <Viewstep3 currentUserView={props.currentUserView} />
            )}
            {value === 4 && (
              <Viewstep4 currentUserView={props.currentUserView} />
            )}
            {value === 5 && (
              <Viewstep5 currentUserView={props.currentUserView} />
            )}
            {value === 6 && (
              <Viewstep6 currentUserView={props.currentUserView} />
            )}
            {value === 7 && (
              <Viewstep7 currentUserView={props.currentUserView} />
            )}
            {value === 9 && (
              <Viewstep8 currentUserView={props.currentUserView} />
            )}
            {value === 8 && (
              <Viewstep9 currentUserView={props.currentUserView} />
            )}
          </Grid>
        </Grid>
      </Box>
    </BootstrapDialog>
  );
};

export default HorizontalNonLinearStepper;
