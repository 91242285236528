import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { reportfinacalData } from './content/data';
import { useNavigate } from 'react-router-dom';
import Alerts from '../../helper/Alert';
import Axios from 'axios';

const FinancialCard = () => {
  const API_URL = process.env.React_App_Api;
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const navigate = useNavigate();
  const handleClick = (path, id) => {
    try {
      Axios.get(`${API_URL}/reports/${id}`).then((res) => {
        if (res.status === 200) {
          navigate(`/reports/${path}`, { state: { reports: res.data } });
        }
      });
    } catch (error) {
      console.log('error', error);
      if (error.response?.status === 503) {
        return setAlert({
          open: true,
          message: 'Database is down. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        // Handle other errors
        return setAlert({
          open: true,
          message: 'An unexpected error occurred. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <Grid container spacing={0} sx={{ mt: 2 }}>
        {reportfinacalData.map((data) => (
          <Grid key={data.id} item xs={12} sm={12} md={3}>
            <Box
              sx={{
                padding: 2,
                paddingBottom: 0,
                borderRadius: 2,
                boxShadow: '0px 0px 5px 0px #ccc',
                m: 0.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  height: '120px',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      bgcolor: '#fae607',
                      borderRadius: '10px',

                      padding: '5px',
                      px: 1,
                      py: 0.8,
                    }}
                  >
                    <BookmarksIcon sx={{ fontSize: '21px' }} />
                  </Box>
                </Box>
                <Box sx={{}}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                    {data.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'gray',
                      fontSize: '14px',
                      mt: 0.5,
                      mb: 1,
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                      margin: 0,
                    }}
                  >
                    {data.list1}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  textAlign: 'center',

                  borderTop: '1px solid #ccc',

                  color: '#1976d2',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'green',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  py: 1.7,
                  justifyContent: 'center',
                }}
                onClick={() => handleClick(data.path, data.id)}
              >
                View Details <KeyboardDoubleArrowRightIcon sx={{ ml: 0.5 }} />
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default FinancialCard;
