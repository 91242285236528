import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Button, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import Bank from './Bank';
import Institution from './Institution';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const EditDetails = (props) => {
  const { handleSubmit, reset } = useForm();

  const handleClose = () => {
    props.setOpen(false);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
  };
  const [getValue, setGetValue] = useState();
  console.log('get', getValue);
  const steps = [
    {
      label: 'Institution Details',
      content: (
        <Institution
          handleNext={handleNext}
          setGetValue={setGetValue}
          currentDetailsValue={props.currentDetailsValue}
          getValue={getValue}
        />
      ),
    },
    {
      label: 'Bank Details',
      content: (
        <Bank
          handleNext={handleNext}
          handleBack={handleBack}
          setGetValue={setGetValue}
          currentDetailsValue={props.currentDetailsValue}
          getValue={getValue}
          setReloading={props.setReloading}
          setOpen={props.setOpen}
          setActiveStep={setActiveStep}
          setAlert={props.setAlert}
        />
      ),
    },
  ];

  const onSubmit = (data) => {
    // Handle form submission
  };

  return (
    <Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="sm"
      >
        <DialogTitle
          id="expenses-add-title"
          style={{
            backgroundColor: '#1976d2',
            marginBottom: '15px',
            padding: '4px',
            color: 'white',
            textAlign: 'center',
            fontSize: '15px',
          }}
        >
          Update Details
          <CloseIcon
            id="expenses-close-icon"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 2,
              marginTop: '1px',
              marginRight: '5px',
              fontSize: '20px',
              color: 'grey.400',
              '&:hover': {
                color: '#f94449',
              },
            }}
          />
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={step.label} {...stepProps}>
                      <StepLabel {...labelProps}>{step.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  {steps[activeStep].content}
                  {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box> */}
                </Fragment>
              )}
            </Box>
          </DialogContent>
        </Box>
      </BootstrapDialog>
    </Fragment>
  );
};

export default EditDetails;
