import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Box,
  Stack,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Menu,
  MenuItem,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  // Dashboard as DashboardIcon,
  School as EducationAid,
  Verified as ProcessAid,
} from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import Logo from '../assets/idea-logo.png';
import FamiliesTable from '../pages/Families/FamiliesTable';
import Dashboard from '../pages/Dashboard/Dashboard';
// import VerticalStepper from "../pages/AidRequest/stepper";
import AidProcess from '../pages/AidProcess/ProcessAid';
// import ButtonAppBar from "../pages/AidRequest/formBar";
import HomePage from '../pages/HomePage';
import GuageMeter from '../pages/GuageMeter/gmeter';
import '../style/MuiStyles.css';
import { styled, useTheme } from '@mui/material/styles';
import ConfirmDialogModal from './LogOut';
import { useOutletContext } from 'react-router-dom';
import Aid from '../pages/admin/Aid';
import '../style/Profile.css';
import PasswordDialog from './PasswordDialog';

import GroupsIcon from '@mui/icons-material/Groups';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Volunteers from '../pages/admin/Volunteers';
import Reports from '../pages/admin/Reports';
import ReportsDetail from '../pages/admin/ReportsDetail';

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // backgroundColor: "red",
  height: '30px',
  padding: '25px',
  // padding: theme.spacing(0, 1),
  // ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '& .MuiToolbar-root': {
    height: '10px',
    color: ' white',
    backgroundColor: '#1976d2',
    padding: '25px',
  },
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      backgroundColor: 'red',
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: 'red',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const { userData } = useOutletContext();
  console.log('USERDATA', userData.role);
  const [open, setOpen] = React.useState(false);
  // const [auth, setAuth] = React.useState(false);
  const [anchorEl, setanchorEl] = React.useState(null);
  const [logOutOpen, setlogOutOpen] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [isPassword, setIsPassword] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openaccount = Boolean(anchorEl);

  const handleClick = (event) => {
    setanchorEl(event.currentTarget);
  };

  const handleChangePassword = () => {
    setPassword(true);
    setIsPassword(true);
  };

  // const handleChange = (event, checked) => { this.setState({ auth: checked }); };
  // const handleMenu = event => { setanchorEl({ anchorEl: event.currentTarget }); };
  const handleClose = () => {
    // setanchorEl({ anchorEl: null });
    setanchorEl(null);
  };
  const theme = useTheme();

  return (
    <>
      {isPassword && (
        <PasswordDialog
          password={password}
          setPassword={setPassword}
          setIsPassword={setIsPassword}
        />
      )}
      <Box sx={{ display: 'flex' }}>
        <React.StrictMode>
          <AppBar
            position="fixed"
            sx={{
              height: 40,
              justifyContent: 'center',
              backgroundColor: '#1976d2',
            }}
            open={open}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginTop: '15px',
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {open ? (
                ''
              ) : (
                <img
                  src={Logo}
                  style={{ width: '40px', height: '40px', marginTop: '12px' }}
                  alt="org-img"
                />
              )}

              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                width={'100%'}
              >
                <Box gridColumn="span 4"> </Box>
                {/* <Box gridColumn="span 2">
                {" "}
                <Typography variant="h3" noWrap component="div">
                  {" "}
                  IDEA USA{" "}
                </Typography>{" "}
              </Box> */}
                <Box gridColumn="span 6"></Box>
              </Box>
              <Stack direction="row" spacing={1}>
                {/* <IconButton
                            aria-owns={open ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        > */}

                {/* </IconButton> */}

                {/* <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                //open={open}
                //onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Change Password</MenuItem>
                <MenuItem onClick={<HomePage />}>Log Out</MenuItem>
              </Menu> */}
                {/* <Typography
                variant="h7"
                noWrap
                component="div"
                sx={{ pt: '15px' }}
              >
                {' '}
                <AccountCircleRoundedIcon />{' '}
                {userData.firstName + ' ' + userData.lastName}
              </Typography> */}
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ pt: '15px' }}
                    aria-controls={openaccount ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openaccount ? 'true' : undefined}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        cursor: 'pointer',
                        marginRight: '5px',
                      }}
                    ></Avatar>{' '}
                    <span style={{ color: 'white' }}>
                      {userData.firstName + ' ' + userData.lastName}
                    </span>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openaccount}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <LockResetIcon fontSize="small" />
                    </ListItemIcon>
                    Reset password
                  </MenuItem>
                </Menu>
              </Stack>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            sx={{ backgroundColor: 'gray' }}
          >
            <DrawerHeader sx={{ bgcolor: '#1976d2', height: '52px' }}>
              <img
                src={Logo}
                style={{
                  width: '44px',
                  height: '44px',
                  marginRight: '30px',
                  // marginTop: "14px",
                }}
                alt="org-img"
              />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <CloseRoundedIcon sx={{ color: 'white' }} />
                ) : (
                  <CloseRoundedIcon sx={{ color: 'white' }} />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <List>
                  {/* <Box style={{ borderBottom: "1px solid gray", px: 1 }}> */}
                  {/* <Link
                  to="/dashboard"
                  className="link"
                  sx={{ borderBottom: '1px solid gray' }}
                >
                  <Tooltip title="Dashboard" placement="right" arrow>
                    <ListItem>
                      <ListItemIcon
                        sx={[
                          { mr: 0.5 },
                          (theme) => ({
                            '&:hover': { color: theme.palette.primary.dark },
                          }),
                        ]}
                      >
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                  </Tooltip>
                </Link> */}
                  {/* </Box> */}
                  {/* <Link to="/families" className="link">
              <Tooltip title="Families" placement="right" arrow>
                <ListItem>
                  <ListItemIcon
                    sx={[
                      { mr: 1 },
                      (theme) => ({
                        "&:hover": { color: theme.palette.primary.dark },
                      }),
                    ]}
                  >
                    <PeopleAlt />
                  </ListItemIcon>
                  <ListItemText primary="Families" />
                </ListItem>
              </Tooltip>
            </Link> */}
                  {/* <Link to="/aid" className="link">
              <Tooltip title="EducationAid" placement="right" arrow>
                <ListItem>
                  <ListItemIcon
                    sx={[
                      { mr: 1 },
                      (theme) => ({
                        "&:hover": { color: theme.palette.primary.dark },
                      }),
                    ]}
                  >
                    <EducationAid />
                  </ListItemIcon>
                  <ListItemText primary="EducationAid" />
                </ListItem>
              </Tooltip>
            </Link> */}
                  {/* <Box style={{ borderBottom: "1px solid gray", px: 1 }}> */}
                  <Link
                    to="/processAid"
                    className="link"
                    sx={{ backgroundColor: 'red' }}
                  >
                    <Tooltip title="ProcessAid" placement="right" arrow>
                      <ListItem>
                        <ListItemIcon
                          id="aid-icon-saidBar"
                          sx={[
                            { mr: 0.5 },
                            (theme) => ({
                              '&:hover': { color: theme.palette.primary.dark },
                            }),
                          ]}
                        >
                          <ProcessAid />
                        </ListItemIcon>
                        <ListItemText primary="ProcessAid" />
                      </ListItem>
                    </Tooltip>
                  </Link>
                  {userData.role.includes('committee head') ? (
                    <Link
                      to="/volunteers"
                      className="link"
                      sx={{ backgroundColor: 'red' }}
                    >
                      <Tooltip title="Volunteers" placement="right" arrow>
                        <ListItem>
                          <ListItemIcon
                            id="aid-icon-saidBar"
                            sx={[
                              { mr: 0.5 },
                              (theme) => ({
                                '&:hover': {
                                  color: theme.palette.primary.dark,
                                },
                              }),
                            ]}
                          >
                            <GroupsIcon />
                          </ListItemIcon>
                          <ListItemText primary="Volunteers" />
                        </ListItem>
                      </Tooltip>
                    </Link>
                  ) : (
                    ''
                  )}

                  {userData.role.includes('committee head') ? (
                    <Link
                      to="/reports"
                      className="link"
                      sx={{ backgroundColor: 'red' }}
                    >
                      <Tooltip title="Reports" placement="right" arrow>
                        <ListItem>
                          <ListItemIcon
                            id="aid-icon-saidBar"
                            sx={[
                              { mr: 0.5 },
                              (theme) => ({
                                '&:hover': {
                                  color: theme.palette.primary.dark,
                                },
                              }),
                            ]}
                          >
                            <SummarizeIcon />
                          </ListItemIcon>
                          <ListItemText primary="Reports" />
                        </ListItem>
                      </Tooltip>
                    </Link>
                  ) : (
                    ''
                  )}
                  {/* </Box> */}
                  {/* <Link to="/setting" className="link">
              <Tooltip title="Setting" placement="right" arrow>
                <ListItem>
                  <ListItemIcon
                    sx={[
                      { mr: 1 },
                      (theme) => ({
                        "&:hover": { color: theme.palette.primary.dark },
                      }),
                    ]}
                  >
                    <SettingsAccessibilityOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Setting" />
                </ListItem>
              </Tooltip>
            </Link> */}
                  {/* <Link to="/donation" className="link">
              <Tooltip title="Donation" placement="right" arrow>
                <ListItem>
                  <ListItemIcon
                    sx={[
                      { mr: 2 },
                      (theme) => ({
                        "&:hover": { color: theme.palette.primary.dark },
                      }),
                    ]}
                  >
                    <VolunteerActivismIcon />
                  </ListItemIcon>
                  <ListItemText primary="Families" />
                </ListItem>
              </Tooltip>
            </Link> */}
                  {/* <Link to="/guagemeter" className="link">
              <Tooltip title="Guage Meter" placement="right" arrow>
                <ListItem>
                  <ListItemIcon
                    sx={[
                      { mr: 2 },
                      (theme) => ({
                        "&:hover": { color: theme.palette.primary.dark },
                      }),
                    ]}
                  >
                    <SpeedOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Families" />
                </ListItem>
              </Tooltip>
            </Link> */}
                </List>
              </Box>
              <Box
                sx={{
                  // mx: "auto",
                  // display: "flex",
                  // alignItems: "end",
                  // justifyContent: "end",
                  // my: "auto",
                  marginTop: '400px',
                }}
              >
                <Tooltip title="LogOut" placement="right" arrow>
                  <ListItem>
                    <ListItemIcon
                      id="aid-icon-saidBar"
                      sx={[
                        { mr: 0.5 },
                        (theme) => ({
                          '&:hover': { color: theme.palette.primary.dark },
                        }),
                      ]}
                    >
                      <PowerSettingsNewIcon
                        onClick={() => setlogOutOpen(true)}
                      />
                      <ConfirmDialogModal
                        open={logOutOpen}
                        setOpen={setlogOutOpen}
                      />
                    </ListItemIcon>
                    <ListItemText primary="LogOut" />
                  </ListItem>
                </Tooltip>
              </Box>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <main>
              <div />
              <Typography noWrap>
                <Routes>
                  {/* <Route index element={<HomePage />} /> */}
                  {/* <Route path="dashboard" element={<Dashboard open={open} />} /> */}
                  {/* <Route path="families" element={<FamiliesTable />} /> */}
                  {/* <Route path="aid" element={<VerticalStepper />} /> */}
                  <Route
                    path="/processAid"
                    element={
                      //   userData.role.map((role) =>
                      //   role === 'committee head' ? <Aid /> : <AidProcess />
                      // )
                      userData.role.includes('committee head') ? (
                        <Aid openDrawer={open} />
                      ) : (
                        <AidProcess openDrawer={open} />
                      )
                    }
                  />
                  {/* <Route path="setting" element={<Setting />} /> */}
                  {/* <Route path="donation" element={<DonationForm />} />
                <Route path="guagemeter" element={<GuageMeter />} /> */}
                </Routes>
              </Typography>
              <Typography noWrap>
                <Routes>
                  <Route path="/volunteers" element={<Volunteers />} />
                </Routes>
              </Typography>
              <Typography noWrap>
                <Routes>
                  <Route path="/reports" element={<Reports />} />
                </Routes>
              </Typography>
              <Typography noWrap>
                <Routes>
                  <Route path="/reports/:id" element={<ReportsDetail />} />
                </Routes>
              </Typography>
            </main>
          </Box>
        </React.StrictMode>
      </Box>
    </>
  );
}
