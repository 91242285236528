import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';

import ArticleIcon from '@mui/icons-material/Article';
import NoteIcon from '@mui/icons-material/Note';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SourceIcon from '@mui/icons-material/Source';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ThreePIcon from '@mui/icons-material/ThreeP';
import axios from 'axios';
import DashCardTemplete from './DashCardTemplete';

const DashBoardCard = (props) => {
  const API_URL = process.env.React_App_Api;
  const { userData } = useOutletContext();
  const [getValue, setGetValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + '/aid/dashboard');
        setGetValue(response.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    const fetchDataBasedOnDistrict = async (districts) => {
      try {
        const payload = {
          districts: districts,
        };
        const response = await axios.post(
          API_URL + '/aid/district/dashboard',
          payload
        );
        setGetValue(response.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    if (
      userData.role.includes('committee head') ||
      userData.role.includes('treasurer') ||
      userData.role.includes('dispersor')
    ) {
      fetchData();
    } else {
      fetchDataBasedOnDistrict(userData.district);
    }
  }, [props.reloading]);

  function AmountFormat(amount) {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2, // Adjust as needed
    }).format(amount);
  }
  const total = 100;

  const NewPercentage =
    getValue.totalRequested > 0
      ? (getValue.totalNewRequest / getValue.totalRequested) * 100
      : 0;
  const ReviewedPercentage =
    getValue.totalRequested > 0
      ? (getValue.totalReviewed / getValue.totalRequested) * 100
      : 0;
  const ApprovedPercentage =
    getValue.totalRequested > 0
      ? (getValue.totalApproved / getValue.totalRequested) * 100
      : 0;

  const RejectePercentage =
    getValue.totalRequested > 0
      ? (getValue.totalRejected / getValue.totalRequested) * 100
      : 0;

  const OnHoldPercentage =
    getValue.totalRequested > 0
      ? (getValue.totalOnHold / getValue.totalRequested) * 100
      : 0;

  const PaidPercentage =
    getValue.totalRequested > 0
      ? (getValue.totalPaid / getValue.totalRequested) * 100
      : 0;

  return (
    <div>
      <Box
        sx={{
          boxShadow: '0px 0px 2px 0px gray',
          borderRadius: '5px',

          display: 'flex',
          justifyContent: 'space-around',
          mt: 2,
          overflow: 'auto',
          width: '90vw',
        }}
      >
        <DashCardTemplete
          total={total}
          icon={<ArticleIcon />}
          title="Total"
          color="skyblue"
          count={getValue.totalRequested}
          amount={getValue.totalRequestedAmount}
        />
        <DashCardTemplete
          total={NewPercentage}
          icon={<ThreePIcon />}
          title="New"
          color="#e803fc"
          count={getValue.totalNewRequest}
          amount={getValue.totalNewRequestAmount}
          // amount="68787878"
        />
        <DashCardTemplete
          total={ReviewedPercentage}
          icon={<FolderCopyIcon />}
          title="Reviewed"
          color="#f4fc03"
          count={getValue.totalReviewed}
          amount={getValue.totalReviewedAmount}
        />
        <DashCardTemplete
          total={OnHoldPercentage}
          icon={<BackupTableIcon />}
          color="gray"
          title="On Hold"
          count={getValue.totalOnHold}
          amount={getValue.totalOnHoldAmount}
        />

        <DashCardTemplete
          total={RejectePercentage}
          icon={<NewspaperIcon />}
          color="red"
          title="Rejected"
          count={getValue.totalRejected}
          amount={getValue.totalRejectedAmount}
        />
        <DashCardTemplete
          total={ApprovedPercentage}
          icon={<NoteIcon />}
          color="green"
          title="Approved"
          count={getValue.totalApproved}
          amount={getValue.totalApprovedAmount}
        />
        <DashCardTemplete
          total={PaidPercentage}
          icon={<SourceIcon />}
          color="orange"
          title="Paid"
          count={getValue.totalPaid}
          amount={getValue.totalPaidAmount}
        />
      </Box>
    </div>
  );
};

export default DashBoardCard;
