// import * as React from "react";
// import { BarChart } from "@mui/x-charts/BarChart";
// import { axisClasses } from "@mui/x-charts";

// const chartSetting = {
//   yAxis: [
//     {
//       label: "Beneficiaries",
//     },
//   ],
//   sx: {
//     [`.${axisClasses.left} .${axisClasses.label}`]: {
//       transform: "translate(-10px, 0)",
//     },
//     marginLeft: "20px",
//   },
// };
// const dataset = [
//   {
//     london: 20,
//     paris: 57,
//     newYork: 86,

//     month: "Jan",
//   },
//   {
//     london: 10,
//     paris: 52,
//     newYork: 78,

//     month: "Feb",
//   },
//   {
//     london: 46,
//     paris: 53,
//     newYork: 106,

//     month: "Mar",
//   },
//   {
//     london: 78,
//     paris: 56,
//     newYork: 92,

//     month: "Apr",
//   },
//   {
//     london: 10,
//     paris: 69,
//     newYork: 92,

//     month: "May",
//   },
//   {
//     london: 5,
//     paris: 63,
//     newYork: 103,

//     month: "June",
//   },
//   {
//     london: 67,
//     paris: 60,
//     newYork: 105,

//     month: "July",
//   },
//   {
//     london: 30,
//     paris: 60,
//     newYork: 106,

//     month: "Aug",
//   },
//   {
//     london: 51,
//     paris: 51,
//     newYork: 95,

//     month: "Sept",
//   },
//   {
//     london: 60,
//     paris: 65,
//     newYork: 97,

//     month: "Oct",
//   },
//   {
//     london: 67,
//     paris: 64,
//     newYork: 76,

//     month: "Nov",
//   },
//   {
//     london: 10,
//     paris: 70,
//     newYork: 103,

//     month: "Dec",
//   },
// ];

// const valueFormatter = (value) => `${value}mm`;

// export default function BarsDataset() {
//   return (
//     <BarChart
//       dataset={dataset}
//       xAxis={[{ scaleType: "band", dataKey: "month", label: "Month" }]}
//       series={[
//         { dataKey: "london", label: "School", stack: "stack1", valueFormatter },
//         { dataKey: "paris", label: "College", stack: "stack1", valueFormatter },
//         {
//           dataKey: "newYork",
//           label: "Professional Degree",
//           stack: "stack1",
//           valueFormatter,
//         },
//       ]}
//       {...chartSetting}
//     />
//   );
// }
import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

import { axisClasses } from "@mui/x-charts";

const chartSetting = {
  yAxis: [
    {
      label: "Beneficiaries",
    },
  ],
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-1px, 0)",
    },
    marginLeft: "10px",
  },
};

export default function ChartsOverviewDemo() {
  return (
    <BarChart
      series={[
        {
          data: [35, 44, 24, 34, 35, 33, 10, 45, 47, 23, 56, 23],
          label: "School",
        },
        {
          data: [6, 49, 56, 8, 34, 47, 12, 56, 43, 23, 10, 32],
          label: "College",
        },
        {
          data: [15, 25, 30, 50, 35, 10, 24, 34, 21, 44, 24, 10],
          label: "Professional Degree",
        },
        // { data: [60, 50, 15, 25] },
      ]}
      xAxis={[
        {
          data: [
            "jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          scaleType: "band",
          label: "Month",
        },
      ]}
      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      {...chartSetting}
    />
  );
}
