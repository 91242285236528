import { Box, Grid } from "@mui/material";
import React from "react";
import GridTemplete from "./Templete/GridTemplete";

const Viewstep2 = (props) => {
  function AmountFormat(amount) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2, // Adjust as needed
    }).format(amount);
  }
  return (
    <Box sx={{ height: "280px", p: 3 }}>
      <Grid container spacing={1}>
        <GridTemplete
          title="Father's/Guardian's Name 
          "
          content={props.currentUserView.fathername}
          md="6"
        />
        <GridTemplete
          title="Father's/Guardian's Occupation
          "
          content={props.currentUserView.fatherprofession}
          md="6"
        />
        <GridTemplete
          title="Mother's Name 
"
          content={props.currentUserView.mothername}
          md="6"
        />
        <GridTemplete
          title="Mother's Occupation
          "
          content={props.currentUserView.motherprofession}
          md="6"
        />

        <GridTemplete
          title="Family Income 
          "
          content={AmountFormat(props.currentUserView.familyincomepm)}
          md="6"
        />
      </Grid>
    </Box>
  );
};

export default Viewstep2;
