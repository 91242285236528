import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import { Box, Button } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

const schema = yup.object().shape({
  accountNumber: yup
    .number()
    .typeError('Account Number must be a number')
    .required('This field required'),

  ifscCode: yup
    .string()
    .required('IFSC Code is required')
    .matches(/^[^\s]+$/, 'IFSC Code cannot contain spaces'),

  bankName: yup
    .string()
    .required('Bank Name is required')
    .max(50, 'Bank Name must be at most 50 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Enter the correct Bank Name'),
  branchName: yup
    .string()
    .required('Branch Name is required')
    .max(30, 'Branch Name must be at most 30 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Enter the correct Branch Name'),
  paymentAddress: yup.string().required('Payment Address is required'),
});

export default function Bank({
  setGetValue,
  setReloading,
  handleNext,
  handleBack,
  currentDetailsValue,
  getValue,
  setOpen,
  setAlert,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      accountNumber: currentDetailsValue?.accountnumber,
      ifscCode: currentDetailsValue?.ifsccode,
      branchName: currentDetailsValue?.branchname,
      paymentAddress: currentDetailsValue?.paymentaddress,
      bankName: currentDetailsValue?.bankname,
    },
  });

  const API_URL = process.env.React_App_Api;
  const onSubmit = (data) => {
    setGetValue((prev) => {
      return { ...prev, ...data };
    });

    const payload = {
      institutiondetails: {
        name: getValue.institutionName,
        address: getValue.institutionAddress,
        city: getValue.institutionCity,
        state: 'Tamil Nadu',
        pincode: getValue.institutionZip,
        website: '',
        email: getValue.institutionEmail,
        mobileNumber: getValue.institutionMobileNumber,
      },
      paymentDetails: {
        ifsccode: data.ifscCode,
        accountnumber: data.accountNumber,
        bankname: data.bankName,
        branchname: data.branchName,
        branchAddress: data.paymentAddress,
        beneficiaryname: getValue.beneficiaryName,
        beneficiaryphone: getValue.beneficiaryPhone,
      },
    };

    try {
      setReloading(true);
      setTimeout(async () => {
        await axios.patch(
          `${API_URL}/aid/studentdetails/` + currentDetailsValue?.id,
          payload
        );
        // Handle response if needed
        setReloading(false);
        setOpen(false);
        setAlert({
          open: true,
          message: 'Details Update Successfully',
          severity: 'success',
        });
        handleBack();
      }, 1000);
    } catch (error) {
      console.error('Error updating data:', error);
      setReloading(false);
      if (error.response?.status === 503) {
        return setAlert({
          open: true,
          message: 'Database is down. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (error.message) {
        return setAlert({
          open: true,
          message: error.message || 'Server Error.Please try again later',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        // Handle other errors
        return setAlert({
          open: true,
          message: 'An unexpected error occurred. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: 'flex',
        }}
      >
        {/* payment Details */}
        {/* <Grid item md={12} sm={12} sx={{ marginBottom: "-15px" }}>
          <Item>
            <Typography
              gutterBottom
              component="div"
              className="fw-semibold text-primary"
            >
              Payment Details
            </Typography>
          </Item>
        </Grid> */}
        <Grid item md={6} sm={12}>
          <Controller
            name="accountNumber"
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <TextField
                {...field} // Spread field props into the TextField component
                id="outlined-textarea"
                label={
                  <label>
                    Account Number
                    <span style={{ color: 'orangered' }}> *</span>
                  </label>
                }
                placeholder="Enter Account Number"
                size="small"
                className="responsive-form"
                error={!!errors.accountNumber}
                helperText={
                  errors.accountNumber ? errors.accountNumber.message : ''
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Controller
            name="ifscCode" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      IFSC Code
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter IFSC Code"
                  size="small"
                  className="responsive-form"
                  error={!!errors.ifscCode}
                  helperText={errors.ifscCode ? errors.ifscCode.message : ''}
                  fullWidth
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Controller
            name="bankName" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Bank Name
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Bank Name"
                  size="small"
                  className="responsive-form"
                  error={!!errors.bankName} // Convert error to boolean
                  helperText={errors.bankName ? errors.bankName.message : ''}
                  fullWidth // Display error message
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Controller
            name="branchName" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue={''} // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Branch Name
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Branch Name"
                  size="small"
                  className="responsive-form"
                  error={!!errors.branchName} // Convert error to boolean
                  helperText={
                    errors.branchName ? errors.branchName.message : ''
                  } // Display error message
                  fullWidth
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Controller
            name="paymentAddress" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue={''} // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Branch Address
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Payment Address"
                  size="small"
                  className="responsive-form"
                  fullWidth
                  error={!!errors.paymentAddress} // Convert error to boolean
                  helperText={
                    errors.paymentAddress ? errors.paymentAddress.message : ''
                  } // Display error message
                />
              </Item>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleBack} sx={{ textTransform: 'none' }}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          sx={{
            fontSize: '13px',
            p: '2px',
            textTransform: 'none',
          }}
        >
          update
        </Button>
      </Box>
    </>
  );
}
